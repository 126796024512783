<template>
  <div class="user_yijian">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="技师名称">
							<el-input v-model="searchText" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="反馈时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      value-format="yyyy-MM-dd">
					    </el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="user_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="expand">
		      <template slot-scope="props">
		        <el-form label-position="left" inline class="demo-table-expand">
		          <el-row>
		          	<el-col :span="24">
				          <el-form-item label="反馈部门：">
				            <span>{{ props.row.dept_name }}</span>
				          </el-form-item>
				       	</el-col>
					  		<el-col :span="24">
				          <el-form-item label="反馈内容：">
				            <span>{{ props.row.content }}</span>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="反馈图片：">
				            <div class="cell el-tooltip">
											<span v-for="(item,index) in props.row.img_list" :key="index" class="el-avatar el-avatar--square" style="height: 60px; width: 60px; line-height: 60px;margin-left: 10px;">
												<el-image :src="item" fit="fit" :preview-src-list="props.row.img_list"></el-image>
											</span>
										</div>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复内容：">
				            <span>{{ props.row.result_content }}</span>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复时间：">
				            <span>{{ props.row.result_time }}</span>
				          </el-form-item>
				        </el-col>
				      </el-row>
		        </el-form>
		      </template>
		    </el-table-column>
				<el-table-column prop="yijian_id" label="ID"></el-table-column>
				<el-table-column prop="nickName" label="技师名称"></el-table-column>
				<el-table-column label="形象照">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.avatarUrl"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="phoneNumber" label="联系电话" width="150"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="content" label="反馈内容" width="250"></el-table-column>
				<el-table-column prop="add_time" label="反馈时间" width="180"></el-table-column>
				<el-table-column label="是否回复">
					<template slot-scope="scope">
						<div>
							<el-tag type="primary" v-if="scope.row.result_status" disable-transitions>已回复</el-tag>
							<el-tag type="warning" v-else disable-transitions>待回复</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column
		      fixed="right"
		      label="操作"
		      width="80">
		      <template slot-scope="scope" v-if="!scope.row.result_status">
		        <el-button type="primary" plain size="small" @click="setHf(scope.row)">回复</el-button>
		      </template>
		    </el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 回复对话框 -->
			<el-dialog title="回复反馈" width="40%" :visible.sync="hfDialog" :modal-append-to-body="false">
				<el-form ref="hfForm" :model="hfForm" label-width="85px" :rules="hfRules">
					<el-row>
						<el-col :span="12">
							<el-form-item label="ID" prop="yijian_id" style="text-align: left;">
								<el-input v-model.number="hfForm.yijian_id" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="回复内容" prop="content" style="text-align: left;">
								<el-input type="textarea" :rows="4" v-model="hfForm.content" placeholder="请输入回复内容"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="hfDialog=false">取消</el-button>
							<el-button type="primary" @click="doSubmit">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				datePicker: "",
				user_list: [],
				hfDialog: false,
				hfForm: {
					yijian_id: '',
					content: ''
				},
				hfRules:{
					content: [{
						required: true,
						message: "请输入回复内容",
						trigger: "blur"
					}, ],
				}
			};
		},
		components: {
			Pagination
		},
		created() {
			that = this;
			if (!this.$store.state.userInfo) {
	        // 防止未登录
	        this.$router.push({path: '/login'})
	    }else{
					this.getUserList();
			}
		},
		methods: {
			getUserList() {
				let that = this;
				var url = 'custom/yijian_list';
				let params = {
					yijian_type: 2,
					nickName: this.searchText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.user_list = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search(){
				this.getUserList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.datePicker = "";
				this.getUserList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getUserList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getUserList();
			},
			setHf(item){
				this.hfForm.yijian_id = item.yijian_id;
				this.hfDialog = true;
			},
			doSubmit(){
				this.$refs.hfForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
    				timer = setTimeout(() => {
							var url = 'custom/hfYijian';
							let params = that.hfForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.hfDialog = false;
									that.$refs.hfForm.resetFields();
									that.$message.success('操作成功');
									that.getUserList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.hfDialog = false;
								that.$message.error('网络错误');
							});
						},300);
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.user_yijian{
		.left{
		  text-align: left;
		  margin-bottom: 20px;
		}
		.demo-table-expand {
	    font-size: 0;
	  }
	  .demo-table-expand label {
	    width: 90px;
	    color: #99a9bf;
	  }
	  .demo-table-expand .el-form-item {
	    margin-right: 0;
	    margin-bottom: 0;
	    width: 50%;
	  }
	}
</style>